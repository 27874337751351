<template>
	<v-container>
		<v-row v-if="post !== null">
			<v-col cols="6">
        <v-row>
          <v-col cols="12">
            <v-card  v-if="!isNew">
              <v-card-text>
                <dl class="row">
                  <dt class="col-sm-3">Автор</dt>
                  <dd class="col-sm-9">{{getManagerNameById(post.created_by)}}</dd>
                  <dt class="col-sm-3">Дата создания</dt>
                  <dd class="col-sm-9">{{getReadableDate(post.created_at)}}</dd>
                </dl>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12">
            <v-card>
              <v-card-text>
                <v-text-field v-model="post.title" placeholder="Название поста" label="Название поста" outlined>
                  <template v-slot:append-outer>
                    <v-tooltip
                      bottom
                    >
                      <template v-slot:activator="{ on }">
                        <v-icon v-on="on">
                          {{mdiInformationOutline}}
                        </v-icon>
                      </template>
                      Никада не сдавайся и не забывай про название поста брат
                    </v-tooltip>
                  </template>
                </v-text-field>
                <editor :model-value.sync="post.text" :text-length.sync="post.length" type="ad-post"></editor>
                <v-row dense>
                  <v-col cols="12">
                    <v-checkbox v-model="post.addButton" label="Добавить кнопку"></v-checkbox>
                  </v-col>
                  <v-col v-if="post.addButton" cols="12">
                    <v-col cols="6">
                      <v-text-field v-model="post.button_text" placeholder="Текст в кнопке" label="Текст в кнопке" outlined></v-text-field>
                    </v-col>

                  </v-col>
                  <v-col cols="6">
                    <v-radio-group v-model="post.tg_method">
                      <v-radio :disabled="post.length>1024" value="sendPhoto" label="Фото сверху"></v-radio>
                      <v-radio value="sendMessage" label="Фото снизу"></v-radio>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" @click="savePost()">Сохранить</v-btn>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
			</v-col>
      <v-col v-if="post.image" cols="6">
        <v-img :src="`https://backend.telestatic.ru/upload/ad_post_images/${post.image}`"></v-img>
        <v-btn class="mt-3" color="error" @click="deleteImage()">Поменять картинку</v-btn>
      </v-col>
      <v-col v-else cols="6">
        <vue2-dropzone
          id="dropzone"
          ref="dropzone"
          class="mb-3"
          :options="dropzoneOptions"
          @vdropzone-success="uploadSuccess"
          @vdropzone-file-added="updateDZHeaders"
        ></vue2-dropzone>
      </v-col>
		</v-row>
	</v-container>
</template>

<script>
import { mdiInformationOutline } from '@mdi/js'
import vue2Dropzone from 'vue2-dropzone'
import httpClient from '../../services/http.service'
import common from '@/components/mixins/common'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: 'ShowAdPost',
  components:{
    editor: () => import('@/components/posts/Editor'),
    vue2Dropzone
  },
  mixins: [common],
  data(){
    return{
      mdiInformationOutline,
      showPostNameTooltip: false,
      isNew: false,
      id: 0,
      post: null,
      dropzoneOptions: {
        autoProcessQueue: true,
        url: 'https://backend.telestatic.ru/upload/ad-post?id=0',
        thumbnailWidth: 150,
        maxFilesize: 100,
      },
    }
  },
  created() {
    this.id = Number(this.$route.params.id)
    if (this.id === 0) {this.isNew = true}
    this.$route.meta.title = 'Загрузка...'
    this.$router.replace({query: {temp: Date.now()}}) //такая еботня - чтобы заставить роутер обновиться и обновить breadcrubms
    if (!this.isNew){
      httpClient.get(`ad-posts/${this.id}?expand=publications`).then(({data})=>{
        this.post = data
        if (data.button_text !== ''){this.post.addButton = true}
        this.dropzoneOptions.url = `https://backend.telestatic.ru/upload/ad-post?id=${data.id}`
        this.$route.meta.title = data.title
        this.$router.replace({query: {temp: undefined}}) //такая еботня - чтобы заставить роутер обновиться и обновить breadcrubms
      })
    }else{
      this.post = {}
      this.dropzoneOptions.autoProcessQueue = false
    }
  },
  methods:{
    savePost(){
      if (this.isNew){
        httpClient.post('ad-posts', this.post).then(result=>{
          console.log('ad post save result: ', result)
          if (result.status === 201){
            this.$toast('Пост сохранён, загружаю картинку...')
            this.$refs.dropzone.setOption('url', `https://backend.telestatic.ru/upload/ad-post?id=${result.data.id}`)
            this.$refs.dropzone.processQueue()
          }else{
            this.$toast.error('Не удалось сохранить пост!')
          }
        })
      }else{
        httpClient.put(`ad-posts/${this.post.id}`, this.post).then(result=>{
          console.log('ad post save result: ', result)
          if (result.status === 200){
            this.$toast('Пост сохранён')
          }else{
            this.$toast.error('Не удалось сохранить пост!')
          }
        })
      }
    },
    deleteImage(){
      this.post.image = false
    },
    uploadSuccess(file, response){
      this.$toast.success('Картинка - загружена, ты - молодец!')
      this.post.image = response
    },
    updateDZHeaders(){
      console.log('update dz headers')
      this.$refs.dropzone.setOption('url', `https://backend.telestatic.ru/upload/ad-post?id=${this.post.id}`)
      this.$refs.dropzone.setOption('headers', { Authorization: `Bearer ${localStorage.getItem('ACCESS_TOKEN')}` })
    },
  }
}
</script>

<style scoped>

</style>
